<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.supplier") }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table
        fixed-header
        height="calc(100vh - 245px)"
        class="table-padding-2"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <!-- <th role="columnheader">
                                <InputFilter :label="$t('labels.warehouse')" :placeholder="$t('labels.warehouse')"
                                    name="warehouse_code" sort-name="warehouse_code" @onFilter="onFilterChange" />
                            </th>
                            <th role="columnheader">
                                <InputFilter :label="$t('labels.pos')" :placeholder="$t('labels.pos')" name="pos_code"
                                    sort-name="pos_code" @onFilter="onFilterChange" />
                            </th> -->
              <th role="columnheader">
                <InputFilter
                  :label="$t('labels.code')"
                  :placeholder="$t('labels.code')"
                  name="code"
                  sort-name="code"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader">
                <SelectFilter
                  :options="typeOptions"
                  :label="$t('labels.classify')"
                  :placeholder="$t('labels.classify')"
                  name="type"
                  sort-name="type"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader">
                <InputFilter
                  :label="$t('labels.name')"
                  :placeholder="$t('labels.name')"
                  name="name"
                  sort-name="name"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader">
                <InputFilter
                  :label="$t('labels.note')"
                  :placeholder="$t('labels.note')"
                  name="note"
                  sort-name="note"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader">
                <InputFilter
                  :label="$t('labels.phone')"
                  :placeholder="$t('labels.phone')"
                  name="phone"
                  sort-name="phone"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader">
                <SelectCity
                  class="c-input-xs"
                  name="id_city"
                  :label="$t('labels.city')"
                  @onChange="onFilterChange"
                />
              </th>
              <th role="columnheader">
                <SelectCounty
                  class="c-input-xs"
                  name="id_county"
                  :id-city="filters.id_city"
                  :label="$t('labels.county')"
                  @onChange="onFilterChange"
                />
              </th>
              <th role="columnheader">
                <SelectWard
                  class="c-input-xs"
                  name="id_ward"
                  :id-county="filters.id_county"
                  :id-city="filters.id_city"
                  :label="$t('labels.ward')"
                  @onChange="onFilterChange"
                />
              </th>
              <th role="columnheader">
                <InputFilter
                  :label="$t('labels.address')"
                  :placeholder="$t('labels.address')"
                  name="address"
                  sort-name="address"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" class="text-center">
                <v-btn
                  color="success"
                  small
                  @click="addConfig"
                  :disabled="isDisabledBtnAdd"
                >
                  {{ $t("labels.add") }}
                </v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in items"
              :key="item.id"
              class="text-center"
            >
              <!-- <td>{{ item.warehouse_codes }}</td>
                            <td>{{ item.pos_codes }}</td> -->
              <td>
                <span
                  v-if="!item.editing || item.id"
                  class="primary--text cursor-pointer text-decoration-underline"
                  @click="showUseDialog(item)"
                >
                  {{ item.code }}
                </span>
                <v-text-field
                  v-else
                  v-model="item.code"
                  :disabled="!item.editing"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td>
                <span v-if="!item.editing">{{
                  $t(`labels.supplier_type_${item.type}`)
                }}</span>
                <v-autocomplete
                  v-else
                  v-model="item.type"
                  :items="typeOptions"
                  :disabled="!item.editing"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-autocomplete>
              </td>
              <td>
                <span v-if="!item.editing">{{ item.name }}</span>
                <v-text-field
                  v-else
                  v-model="item.name"
                  :disabled="!item.editing"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td>
                <span v-if="!item.editing">{{ item.note }}</span>
                <v-text-field
                  v-else
                  v-model="item.note"
                  :disabled="!item.editing"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td>
                <span v-if="!item.editing">{{ item.phone }}</span>
                <v-text-field
                  v-else
                  v-model="item.phone"
                  :disabled="!item.editing"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td>
                <span v-if="!item.editing" class="text-capitalize">{{
                  item.city_name
                }}</span>
                <SelectCity
                  v-else
                  class="c-input-xs"
                  :id-item="item.id"
                  :id-city="item.id_city"
                  :label="$t('labels.city')"
                  name="id_city"
                  @onChange="onItemChange"
                />
              </td>
              <td>
                <span v-if="!item.editing" class="text-capitalize">{{
                  item.county_name
                }}</span>
                <SelectCounty
                  v-else
                  class="c-input-xs"
                  :id-item="item.id"
                  :id-city="item.id_city"
                  :id-county="item.id_county"
                  :label="$t('labels.county')"
                  name="id_county"
                  @onChange="onItemChange"
                />
              </td>
              <td>
                <span v-if="!item.editing" class="text-capitalize">{{
                  item.ward_name
                }}</span>
                <SelectWard
                  v-else
                  class="c-input-xs"
                  :id-item="item.id"
                  :id-city="item.id_city"
                  :id-county="item.id_county"
                  :id-ward="item.id_ward"
                  :label="$t('labels.ward')"
                  name="id_ward"
                  @onChange="onItemChange"
                />
              </td>
              <td>
                <span v-if="!item.editing">{{ item.address }}</span>
                <v-text-field
                  v-else
                  v-model="item.address"
                  :disabled="!item.editing"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td class="py-1">
                <template v-if="!item.editing">
                  <v-btn
                    x-small
                    color="warning"
                    @click="toggleEditing(item, index, true)"
                    style="width: 70px"
                  >
                    {{ $t("labels.edit") }}
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn
                    class="mb-1"
                    x-small
                    color="success"
                    @click="updateConfig(item)"
                    style="width: 70px"
                  >
                    {{ $t("labels.save") }}
                  </v-btn>
                  <br />
                  <v-btn
                    x-small
                    color="error"
                    @click="toggleEditing(item, index, false)"
                    style="width: 70px"
                  >
                    {{ $t("labels.cancel") }}
                  </v-btn>
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div class="pt-3">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="5"
        ></v-pagination>
      </div>
    </v-card-text>

    <v-dialog v-model="useDialog" persistent max-width="820px">
      <WpSupplierDialog
        v-if="useDialog"
        @cancel="hideUseDialog"
        :supplier="supplier"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { YES_NO_OPTIONS, SUPPLIER_TYPE_OPTIONS } from "@/libs/const";
import { debounce } from "lodash/function";

export default {
  name: "SupplierDialog",
  components: {
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectCity: () => import("@/components/common/SelectCity"),
    SelectCounty: () => import("@/components/common/SelectCounty"),
    SelectWard: () => import("@/components/common/SelectWard"),
    WpSupplierDialog: () => import("@/components/packaging/WpSupplierDialog"),
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    totalItem: 0,
    totalActive: 0,
    items: [],
    filters: {},
    isLoading: false,
    statusOptions: [...YES_NO_OPTIONS],
    supplier: {},
    useDialog: false,
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
    typeOptions() {
      return [...SUPPLIER_TYPE_OPTIONS].map((t) => ({
        text: this.$t(`labels.supplier_type_${t}`),
        value: t,
      }));
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    showUseDialog(item) {
      this.supplier = { ...item };
      this.useDialog = true;
    },
    hideUseDialog() {
      this.supplier = {};
      this.useDialog = false;
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    addConfig() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      this.items.unshift({
        id: null,
        id_warehouse: null,
        id_pos: null,
        code: null,
        type: null,
        name: null,
        note: null,
        phone: null,
        address: null,
        id_city: null,
        id_county: null,
        id_ward: null,
        editing: true,
      });
    },
    onItemChange(val) {
      const items = [...this.items];
      let item = [...items].find((i) => i.id === val.id);
      if (item) {
        const itemIndex = [...items].findIndex((i) => i.id === val.id);
        item = { ...item, [val.name]: val.value };
        items[itemIndex] = item;
        this.items = [...items];
      }
    },
    async updateConfig(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/packaging/v1/supplier-save", item);
        this.isLoading = false;
        this.getList();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    getList: debounce(function () {
      httpClient
        .post("/packaging/v1/supplier-list", {
          ...this.filters,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.totalItem = data.total;
          this.items = [...data.rows].map((item) => ({
            ...item,
            editing: false,
          }));
        });
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
  },
};
</script>

<style scoped></style>
